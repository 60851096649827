@import "~@fontsource/open-sans/300.css";
@import "~@fontsource/open-sans/400.css";
@import "~@fontsource/open-sans/500.css";
@import "~@fontsource/open-sans/600.css";
@import "~@fontsource/open-sans/700.css";
@import "~@fontsource/open-sans/800.css";

body {
  font-family: Open Sans, sans-serif;
}

.leaflet-container {
  z-index: 1 !important;
}

.mantine-SegmentedControl-indicator {
  width: 200px !important;
  height: 32px !important;
}
