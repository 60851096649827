.panel {
  flex: 0.4;
  background-color: #fff;
  border-right: 1px solid #f1f1f1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100vh - 50px);
  min-width: 250px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 15px;
  overflow-y: auto;
}

.actionBar {
  display: flex;
  flex-direction: row;
  height: 50px;
  border-top: 1px solid #f1f1f1;
  align-items: center;
  justify-content: space-around;
}

.divider {
  height: 25px;
  width: 1px;
  background-color: #f1f1f1;
}
