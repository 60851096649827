.header {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #f1f1f1;
  justify-content: space-between;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
  margin-left: 10px;
}
