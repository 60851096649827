.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  background-color: blue;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: row;
  background-color: green;
}
